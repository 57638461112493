.logout,
.alert {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cancel-light {
    background: white;
    border: 2px solid #262626;
    &:hover {
      background: #e8f2fe;
    }
  }
  .ok-light {
    background: #262626;
    border: 2px solid #262626;
    &:hover {
      background: #4097ff !important;
      border: 2px solid #4097ff;
    }
  }

  .cancel-dark {
    background: #2d2c2c;
    border: 2px solid white;
    &:hover {
      color: #fdb813;
      border: 2px solid fdb813;
    }
  }
  .ok-dark {
    background: white;
    border: 2px solid white;
    color: #262626;

    &:hover {
      background: #fdb813;
      border: 2px solid #fdb813;
      color: #262626 !important;
    }
  }

  .buttons-wrapper {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
}

.ad-setup-modal,
.publisher-modal {
  .form-item {
    width: 48%;
  }

  .full-width {
    width: 100%;
  }

  .location {
    width: 48%;
    align-self: center;
  }

  .buttons {
    justify-content: center;
    gap: 16px;
  }
}

.custom-table :where(.css-dev-only-do-not-override-1t6tm2e).ant-empty-normal {
  margin-block: 0 !important;
}

.new-domain-modal {
  .form-item {
    width: 24%;
  }

  .full-width {
    width: 100%;
  }

  .buttons {
    justify-content: center;
    gap: 20px;
    padding:20px;
  }
  h2{
    padding-top:22px;
  }
 .padding{
  padding-left:26px;
  padding-right: 26px;
 }
 
 .item-add-button{
  margin-top: 43px;
 }


}
.platform .ant-select-selection-item {
  font-size: 12px;
  border-radius: 20px !important;
  margin-right: 5.3px !important;
}
.new-domain-modal .ant-modal-content {
  padding: 0 !important;
}
.loading-spinner {
  padding: 100px;
  margin: 100px;
}

