.revenue {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .center-container {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}
