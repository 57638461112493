.advertisers {
  .icon {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .firstLetterUppercase {
    text-transform: capitalize;
  }
}


