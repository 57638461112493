.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 16px;
  margin-bottom: 100;
  padding: 40px;

  .cursor-pointer {
    cursor: pointer;
  }
}

.content-row {
  margin-top: 24px;
  margin-bottom: 16px;
}

.table-striped-rows-light tr {
  background-color: white;

  &:nth-child(2n) {
    background-color: #e8f2fe;
    &:hover td {
      background-color: #cbe3ff;
    }
  }
  &:hover td {
    background-color: #ededed;
  }
}

.table-striped-rows-dark tr {
  background-color: #2d2c2c;

  &:nth-child(2n) {
    background-color: #212121;
    &:hover td {
      background-color: #5f5f5f;
    }
  }
  &:hover td {
    background-color: #5f5f5f !important;
  }
}

.metrics {
  margin-top: 24px;
  height: 500px;
  display: flex;
  justify-content: space-between;

  .metrics-items {
    padding: 0 !important;
    border-radius: 10px;
    right: 0;
    bottom: 0;
    width: "20%";

    .card-light {
      display: flex;
      align-items: center;
      height: 76px;
      background-color: white;
      border-radius: 3px;

      &:hover {
        background-color: #e8f2fe !important;
      }
      &:active {
        box-shadow: 0 4px 12px rgba(22, 33, 74, 0.12);
        margin: 0 1rem;
      }
    }

    .card-dark {
      display: flex;
      align-items: center;
      height: 76px;
      background-color: white;
      border-radius: 3px;

      &:hover {
        background-color: #da9f13 !important;
      }
      &:active {
        box-shadow: 0 4px 12px rgba(22, 33, 74, 0.12);
        margin: 0 1rem;
      }
    }
  }
  .metrics-selected {
    width: "75%";
    height: 500px;
    border-radius: 10px;
    overflow: scroll;
    padding: 0 !important;
    background-color: transparent !important;

    > div {
      background-color: transparent !important;
    }
  }
}
