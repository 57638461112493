@import "../../colors";
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.card {
  margin-top: 16px;
  h4 {
    text-transform: uppercase;
    font-weight: 400;
  }

  .action-title {
    margin: 12px;
    color: $text-card-action-color;
  }
}

.intuitive-card {
    font-family: "Nunito", sans-serif;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    padding: 16px;
    width: auto;
    margin: 10px;
    color: #262626;
    font-weight: 400;
    font-size: 16px;
    background-color: #fff;
    box-sizing: border-box;
    max-height: 200px;
    overflow-x: hidden;

  .intuitive-title {
    color: #4097FF;
    margin-bottom: 20px;
    border-bottom: 1px solid #8E8E8E;
    line-height: 50px;
  }
  .intuitive-content {
     /*  display: flex; */
      margin: 0;
      padding: 0;
      justify-content: space-between;
      align-items: center; /* flex-end; */
  }
  .intuitive-total {
      font-size: 28px;
      font-weight: 700;
      display: inline-flex;
      align-items: baseline;
  }
  .intuitive-total span {
      margin-left: 10px;
  }
  .intuitive-details {
      box-sizing: border-box;
      text-align: right;
      margin-top: 10px;
  }
  .intuitive-details span {
      margin-left: 8px;
      display: inline-block;
      white-space: nowrap; 
  }
  .grey { 
      color: #8E8E8E; 
      font-weight: 400; 
      font-size: 16px; 
  }

  &.intuitive-dark-card {
    background-color: #141414;
    color: white;
    border: 1px solid #424242;
  
    .intuitive-title { color: white; border-bottom: 1px solid #424242; }
  
    .grey {
      color: rgba(255, 255, 255, 0.65);
    }
  }
}



@media screen and (max-width: 1899px) {
  .intuitive-card .intuitive-content { display: block; }
}