.ads-button-light {
  background-color: white !important;
  &:hover {
    background-color: #e8f2fe !important;
  }
}

.ads-button-dark {
  background-color: #212121 !important;
  &:hover {
    background-color: rgba(253, 184, 19, 0.25) !important;
  }
}

.general-outline-button-dark {
  background-color: #212121 !important;
  border-width: 1px;
  border-color: white;
  &:hover {
    background-color: rgba(253, 184, 19, 0.25) !important;
    color: white !important;
  }
  &:focus {
    background-color: #fdb813 !important;
    border-color: #fdb813;
    color: #262626 !important;
  }
}

.general-outline-button-light {
  background-color: rgba(217, 217, 217, 1) !important;
  border-width: 1px;
  border-color: #d9d9d9;
  color: #8e8e8e;
  &:hover {
    background-color: #e8f2fe !important;
    color: #4097ff;
    border: 1px solid #4097ff;
  }
  &:focus {
    background-color: #4097ff !important;
    border-color: #4097ff;
    color: white !important;
  }
}
