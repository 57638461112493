@import "../../colors";

.header {
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  padding-left: 5%;
  padding-right: 5%;
}
