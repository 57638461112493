@import "../../colors";

.wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.intro-side {
  background-image: url("../../../src/assets/images/pubDashSideSvg.svg");
  background-size: cover;
  color: #fff;
  vertical-align: middle;
  animation: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
}
.logo {
  width: 18%;
}
.header-logo {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 24px;
  width: 70%;
  gap: 32px;
}

.intro-side-content {
  flex-direction: column;
  justify-content: space-around;
  display: flex;
}

.content-side {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
}

.auto-complete {
  display: flex;
  align-self: center;
  height: 80%;
  flex-direction: column;

  .domain-header {
    height: 30%;
  }
  .button {
    height: 10%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 30px;
  }
}

.selectDomain {
  color: #5f5f5f;
}
@media (max-width: 700px) {
  .wrapper {
    flex-direction: column;
    overflow: scroll;
    height: 100%;
  }

  .header-logo {
    width: 90%;
  }

  .auto-complete {
    width: 80%;
  }

  .intro-side {
    width: 100%;
    padding: 0;
    overflow-x: hidden;
    height: 25%;
  }
  .logo {
    width: 10%;
  }
}

.ant-skeleton-element {
  width: 100% !important;
}
