body {
  margin: 0;
  font-family: Nunito;
}

h1 {
  font-weight: 700;
  margin: 0;
  font-size: 2rem;
  font-family: Nunito;
}

h2 {
  font-weight: 300;
  margin: 0;
  font-family: Nunito;
}
