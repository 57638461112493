.embed-scanner-wrapper {
  width: 95%;
  height: 100vh;
  display: flex;
  gap: 32px;
  padding: 40px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  overflow: scroll;
  align-items: center;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .urls-wrapper {
    width: 100%;
    margin-top: 24px;
    max-height: 300px;
    overflow: scroll;
  }
  .list-item {
    width: 100%;
    border-radius: 8px;
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .platforms {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }
}
