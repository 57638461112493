.publishers {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.publishers {
  .icon {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.center-column-title {
  text-align: center;
}
.pub-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 40px;
}
.ant-empty-normal {
  margin-block: 0 !important;
}
