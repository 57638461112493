@import "../../colors";
.layout-wrapper {
  display: flex;
  flex-direction: column;
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 16px;
    margin-bottom: 100;
    padding: 40px;
    height: 100vh;
    overflow: scroll;
  }
}
