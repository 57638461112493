.hide-unit {
  height: "100%";
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  .title {
    color: #4097ff;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .tag {
    margin-top: auto;
    margin-bottom: auto;
  }
  .unit-header {
    height: 110px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  .unit-history-header {
    width: 100%;
    justify-content: start;
    gap: 16px;
  }

  .buttons {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .before-select {
    background-color: #d9d9d9;
    color: #8e8e8e;
    border: 1px solid #d9d9d9;

    &:hover {
      background: #d9d9d9;
      color: #262626;
      border: 1px solid #262626;
    }
  }
  .selectedItems {
    background-color: #4097ff;
    color: white;
    border: 1px solid #4097ff;
  }

  .show-button {
    background-color: #e8f2fe;
    color: #4097ff;
    border: 1px solid #4097ff;
  }

  .iframe-loading {
    display: none;
  }
  .iframe-display {
    display: block;
    border-width: 2px;
    border-radius: 10px;
    border: none;
    display: flex;
    width: 100%;
    margin-bottom: 24px;
  }

  .filters-wrapper {
    display: flex;
    gap: 16px;
  }
  .filter-button {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .platform-tag {
    display: flex;
    gap: 12px;
  }
  .full-width {
    width: 100%;
  }
  .layouts-buttons {
    display: flex;
    gap: 16px;
  }
  .layout-button {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .discover {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }
  .discover-content {
    color: #8e8e8e;
    font-size: 20px;
  }
  .filter-button {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
  .social-items-container {
    gap: 20px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
    margin-top: 16px;
    margin-bottom: 70px;
  }
  .back-to-discover {
    width: 100%;
    flex-direction: column;
    display: flex;
  }
  .loading-items {
    gap: 16px;
    align-items: center;
  }
}
