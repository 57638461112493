.ads-txt-line-container {
  width: 80%;
  height: 100vh;
  display: flex;
  gap: 32px;
  padding: 40px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}

.ads-txt-multi-container {
  width: 60%;
  height: 100vh;
  display: flex;
  gap: 32px;
  padding: 40px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;

  .icon {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    width: 100%;
    height: 30;
    display: flex;
    justify-content: center;
  }
  .back-button {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .area {
    display: flex;
    height: 350px;
  }
}
