@import "../../colors";

.pure-card {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  margin-top: 16px;
}
.table-card {
  padding-bottom: 1px;
}
